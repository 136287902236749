<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="branch_code">Branch Code</vs-th>
        <vs-th sort-key="branch_name">Branch Name</vs-th>
        <vs-th sort-key="division">Division</vs-th>
        <vs-th sort-key="type">Type</vs-th>
        <vs-th sort-key="po_number">PO Number</vs-th>
        <vs-th sort-key="ref_doc">Ref Doc</vs-th>
        <vs-th sort-key="po_type">PO Type</vs-th>
        <vs-th sort-key="remarks">Remarks</vs-th>
        <vs-th sort-key="po_status">PO Status</vs-th>
        <vs-th sort-key="doc_notes">Doc Notes</vs-th>
        <vs-th sort-key="no_do_principal">No DO Principal</vs-th>
        <vs-th sort-key="gr_ir_no">GR/IR No</vs-th>
        <vs-th sort-key="gr_ir_date">GR/IR Date</vs-th>
        <vs-th sort-key="billing_number">Billing Number</vs-th>
        <vs-th sort-key="billing_doc_date">Billing Doc Date</vs-th>
        <vs-th sort-key="doc_type">Doc Type</vs-th>
        <vs-th sort-key="doc_date">Doc Date</vs-th>
        <vs-th sort-key="posting_date">Posting Date</vs-th>
        <vs-th sort-key="vendor_code">Vendor Code</vs-th>
        <vs-th sort-key="vendor_name">Vendor Name</vs-th>
        <vs-th sort-key="material_code_internal">Material Code Internal</vs-th>
        <vs-th sort-key="material_code_external">Material Code External</vs-th>
        <vs-th sort-key="material_description">Material Description</vs-th>
        <vs-th sort-key="item_qty">Item Qty</vs-th>
        <vs-th sort-key="po_qty">PO Qty</vs-th>
        <vs-th sort-key="gr_qty">GR Qty</vs-th>
        <vs-th sort-key="sub_total">Sub Total</vs-th>
        <vs-th sort-key="total_discount">Total Discount</vs-th>
        <vs-th sort-key="total_price_gr">Total Price GR</vs-th>
        <vs-th sort-key="discount_model">Discount Model</vs-th>
        <vs-th sort-key="discount_value">Discount Value</vs-th>
        <vs-th sort-key="discount_amount">Discount Amount</vs-th>
        <vs-th sort-key="net">Net</vs-th>
        <vs-th sort-key="item_uom">Item UOM</vs-th>
        <vs-th sort-key="total_price">Total Price</vs-th>
        <vs-th sort-key="to_be_delivered_qty">To Be Delivered Qty</vs-th>
        <vs-th sort-key="to_be_delivered_amt">To Be Delivered Amt</vs-th>
        <vs-th sort-key="unit_price">Unit Price</vs-th>
        <vs-th sort-key="tax_amount">Tax Amount</vs-th>
        <vs-th sort-key="rdd">RDD</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].branch_code">
            {{ data[indextr].branch_code }}
          </vs-td>
          <vs-td :data="data[indextr].branch_name">
            {{ data[indextr].branch_name }}
          </vs-td>
          <vs-td :data="data[indextr].division">
            {{ data[indextr].division }}
          </vs-td>
          <vs-td :data="data[indextr].type"> {{ data[indextr].type }} </vs-td>
          <vs-td :data="data[indextr].po_number">
            {{ data[indextr].po_number }}
          </vs-td>
          <vs-td :data="data[indextr].ref_doc">
            {{ data[indextr].ref_doc }}
          </vs-td>
          <vs-td :data="data[indextr].po_type">
            {{ data[indextr].po_type }}
          </vs-td>
          <vs-td :data="data[indextr].remarks">
            {{ data[indextr].remarks }}
          </vs-td>
          <vs-td :data="data[indextr].po_status">
            {{ data[indextr].po_status }}
          </vs-td>
          <vs-td :data="data[indextr].doc_notes">
            {{ data[indextr].doc_notes }}
          </vs-td>
          <vs-td :data="data[indextr].no_do_principal">
            {{ data[indextr].no_do_principal }}
          </vs-td>
          <vs-td :data="data[indextr].gr_ir_no">
            {{ data[indextr].gr_ir_no }}
          </vs-td>
          <vs-td :data="data[indextr].gr_ir_date">
            {{ data[indextr].gr_ir_date }}
          </vs-td>
          <vs-td :data="data[indextr].billing_number">
            {{ data[indextr].billing_number }}
          </vs-td>
          <vs-td :data="data[indextr].billing_doc_date">
            {{ data[indextr].billing_doc_date }}
          </vs-td>
          <vs-td :data="data[indextr].doc_type">
            {{ data[indextr].doc_type }}
          </vs-td>
          <vs-td :data="data[indextr].doc_date">
            {{ data[indextr].doc_date }}
          </vs-td>
          <vs-td :data="data[indextr].posting_date">
            {{ data[indextr].posting_date }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_code">
            {{ data[indextr].vendor_code }}
          </vs-td>
          <vs-td :data="data[indextr].vendor_name">
            {{ data[indextr].vendor_name }}
          </vs-td>
          <vs-td :data="data[indextr].material_code_internal">
            {{ data[indextr].material_code_internal }}
          </vs-td>
          <vs-td :data="data[indextr].material_code_external">
            {{ data[indextr].material_code_external }}
          </vs-td>
          <vs-td :data="data[indextr].material_description">
            {{ data[indextr].material_description }}
          </vs-td>
          <vs-td :data="data[indextr].item_qty">
            {{ formatPrice(data[indextr].item_qty) }}
          </vs-td>
          <vs-td :data="data[indextr].po_qty">
            {{ formatPrice(data[indextr].po_qty) }}
          </vs-td>
          <vs-td :data="data[indextr].gr_qty">
            {{ formatPrice(data[indextr].gr_qty) }}
          </vs-td>
          <vs-td :data="data[indextr].sub_total">
            {{ formatPrice(data[indextr].sub_total) }}
          </vs-td>
          <vs-td :data="data[indextr].total_discount">
            {{ formatPrice(data[indextr].total_discount) }}
          </vs-td>
          <vs-td :data="data[indextr].total_price_gr">
            {{ formatPrice(data[indextr].total_price_gr) }}
          </vs-td>
          <vs-td :data="data[indextr].discount_model">
            {{ data[indextr].discount_model }}
          </vs-td>
          <vs-td :data="data[indextr].discount_value">
            {{ formatPrice(data[indextr].discount_value) }}
          </vs-td>
          <vs-td :data="data[indextr].discount_amount">
            {{ formatPrice(data[indextr].discount_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].net">
            {{ formatPrice(data[indextr].net) }}
          </vs-td>
          <vs-td :data="data[indextr].item_uom">
            {{ data[indextr].item_uom }}
          </vs-td>
          <vs-td :data="data[indextr].total_price">
            {{ formatPrice(data[indextr].total_price) }}
          </vs-td>
          <vs-td :data="data[indextr].to_be_delivered_qty">
            {{ formatPrice(data[indextr].to_be_delivered_qty) }}
          </vs-td>
          <vs-td :data="data[indextr].to_be_delivered_amt">
            {{ formatPrice(data[indextr].to_be_delivered_amt) }}
          </vs-td>
          <vs-td :data="data[indextr].unit_price">
            {{ formatPrice(data[indextr].unit_price) }}
          </vs-td>
          <vs-td :data="data[indextr].tax_amount">
            {{ formatPrice(data[indextr].tax_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].rdd"> {{ data[indextr].rdd }} </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    poTypeIDs: {
      type: Array,
    },
    poTypeNames: {
      type: Array,
    },
    vendorIDs: {
      type: Array,
    },
    vendorNames: {
      type: Array,
    },
    statusIDs: {
      type: Array,
    },
    statusNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportPO.tableData,
      total: (state) => state.reportPO.total,
      totalPerPage: (state) => state.reportPO.totalPerPage,
      totalPage: (state) => state.reportPO.totalPage,
      totalSearch: (state) => state.reportPO.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({
      getTableData: 'reportPO/getPOReport',
      generatePOReport: 'reportPO/generatePOReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'branch_code',
        sort: 'desc',
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search == '' ? undefined : this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_doc_posting_date:
          this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
        end_doc_posting_date:
          this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        vendor_code:
          this.vendorIDs.length == 0 ? undefined : this.vendorIDs.join(','),
        po_type:
          this.poTypeIDs.length == 0 ? undefined : this.poTypeIDs.join(','),
        status:
          this.statusIDs.length == 0 ? undefined : this.statusIDs.join(','),
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      this.generatePOReport({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search == '' ? undefined : this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_doc_posting_date:
          this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
        end_doc_posting_date:
          this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        vendor_code:
          this.vendorIDs.length == 0 ? undefined : this.vendorIDs.join(','),
        po_type:
          this.poTypeIDs.length == 0 ? undefined : this.poTypeIDs.join(','),
        status:
          this.statusIDs.length == 0 ? undefined : this.statusIDs.join(','),
        file: file,
      }).then((resp) => {
        this.$vs.loading.close();
        if (resp.status != 'OK') {
          this.$vs.notify({
            color: 'danger',
            title: 'Error',
            text: resp.message,
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-x-circle',
          });
        } else {
          this.$vs.notify({
            color: 'success',
            title: 'Processing',
            text: resp.message,
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-x-circle',
          });
        }
      })
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
